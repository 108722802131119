
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
//import { ModuleWithProviders } from "@angular/core";
import { DragulaService } from 'ng2-dragula';
import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import {LoginService} from './login/login.service';
//import { NativeScriptHttpModule } from "nativescript-angular/http";
//import { NativeScriptModule } from 'nativescript-angular/nativescript.module';
//import { NativeScriptHttpClientModule } from 'nativescript-angular/http-client';
//import { ApolloModule, Apollo } from 'apollo-angular';
//import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import {UserService}  from './setting/UserDetails/user.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import {NgxTreeSelectModule} from 'ngx-tree-select';
import {
    NbGetters, NbTreeGridDataSource, NbTreeGridDataSourceBuilder, NbTreeGridModule
  } from '@nebular/theme';
  import { NbThemeModule } from '@nebular/theme';

import * as $ from 'jquery';
import {KeyValuePipe} from '../app/customPipes/key-value.pipe';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        KeyValuePipe,
      
    ],
    imports: [
        BrowserAnimationsModule,
        StoreModule.forRoot({}),
        AppRoutingModule,
        SharedModule,
        HttpModule,
        NgSelectModule, 
        FormsModule,
        NbThemeModule.forRoot(),
        NbTreeGridModule,
        NgxTreeSelectModule.forRoot({
            allowFilter: true,
            filterPlaceholder: 'Type your filter here...',
            maxVisibleItemCount: 5,
            idField: 'id',
            textField: 'name',
            childrenField: 'children',
            allowParentSelection: true,
            expandMode:"all"
          }),
        HttpClientModule,
     //   ModuleWithProviders,
        ToastrModule.forRoot(),
        NgbModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
              }
        }),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB631g78N2jH-Kdg60vkUKIfXPfs9xljN0'
        })
    ],
    providers: [
        AuthService,
        AuthGuard,
        UserService,
        DragulaService,
        AppComponent,
        KeyValuePipe
    ],
    exports:[KeyValuePipe],
    bootstrap: [AppComponent]
})
export class AppModule { }

