import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { catchError } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';
import { BranchesService } from '../../branches/branches.service';
import { HttpErrorResponse } from '@angular/common/http';


@Injectable()
export class UserService {
  public rootuserId:any;
  public editUserDetails: any;
  public access_token: any;
  public editUserID: any;
  public userFormTamplateId: any;
  public userCreation: Object;
  public tabIndex: any;
  public createUserCount:number = 0;
  public bankName:string;

  /**
   * @constructor
   * @param {Http} http - The http service.
   * @param {LoginService} loginService - The login service.
   * @param {AppService} commonservice - The app service.
   * @param {BranchesService} branchesService - The branch service.
   */
  constructor(
    private http: Http,
    private loginService: LoginService, private commonservice: AppService, private branchesService: BranchesService
  ) {
    this.access_token = loginService.access_tokenMethod();
  }

  getUserForms() {
    return this.http.get(this.commonservice.rootURL + '/users/forms?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getRootForms() {
    return this.http.get(this.commonservice.rootURL + '/users/root/forms?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getUserFormByID(formID) {
    return this.http.get(this.commonservice.rootURL + '/users/forms/' + formID + '?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getUser() {
    return this.http.get(this.commonservice.rootURL + '/users?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  moveUserHierarchy(payload) {
    return this.http.post(this.commonservice.rootURL + '/changeUserHierarchy?access_token=' + this.access_token, payload)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getUserHeirarchy() {
    return this.http.get(this.commonservice.rootURL + '/users/data/all?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  checkUserValidation(payload){
    return this.http.post(this.commonservice.rootURL + '/userValidation',payload)
      .map((res: Response) => res.json())
  }

  getUserbyID(UserID) {
    return this.http.get(this.commonservice.rootURL + '/users/' + UserID + '?access_token=' + this.loginService.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  createUser(userDetails) {
    return this.http.post(this.commonservice.rootURL + `/users?access_token=` + this.access_token, userDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  editUserDetailsService(userDetails, userId) {
    return this.http.put(this.commonservice.rootURL + '/users/' + userId + '?access_token=' + this.access_token, userDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  deleteUser(deleteUserID) {
    return this.http.delete(this.commonservice.rootURL + `/users/` + deleteUserID + '?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  attachDetachRoles(userId, roleDetails) {
    return this.http.put(this.commonservice.rootURL + '/users/' + userId + '/roles?access_token=' + this.access_token, roleDetails).map((res: Response) => {
      return res.json()
    }).catch(this.errorHandler);
  }

  attachDetachGroups(userId, roleDetails) {
    return this.http.put(this.commonservice.rootURL + '/users/' + userId + '/groups?access_token=' + this.access_token, roleDetails).map((res: Response) => {
      return res.json()
    }).catch(this.errorHandler);
  }

  attachDetachRolesGet(userId) {
    return this.http.get(this.commonservice.rootURL + '/users/' + userId + '/roles?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  attachDetachGroupsGet(userId) {
    return this.http.get(this.commonservice.rootURL + '/users/' + userId + '/groups?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getLinkedBranches(userID) {
    return this.http.get(this.branchesService.branchPort() + '/provideLinkedUserToBranch/' + userID + '?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  updateLinkUserToBranch(userId, branchesLinked) {
    return this.http.put(this.branchesService.branchPort() + '/updateLinkUserToBranch/' + userId + '?access_token=' + this.access_token, branchesLinked).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  updateRootUser(payload, rootuserID) {
    return this.http.put(this.commonservice.rootURL + '/updateRootuser/' + rootuserID + '?access_token=' + this.access_token, payload).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  editUserForm(userDetails, userFormTemplateID) {
    return this.http.put(this.commonservice.rootURL + '/users/forms/' + userFormTemplateID + '?access_token=' + this.access_token, userDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  createUserForms(userForms) {
    return this.http.post(this.commonservice.rootURL + `/users/forms?access_token=` + this.access_token, userForms).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  createUserHierarchy(userID, payload) {
    return this.http.post(this.commonservice.rootURL + '/saveuserbyuserhirarchy/' + userID + '?access_token=' + this.access_token, payload).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getRootUserByID(UserID) {
    return this.http.get(this.commonservice.rootURL + '/users/root/' + UserID + '?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getRootUser() {
    return this.http.get(this.commonservice.rootURL + '/users/root?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  OauthDetails() {
    return this.http.get(this.commonservice.rootURL + `/oauthDetails/` + '?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideRootUserVisibility(UserID) {
    return this.http.get(this.commonservice.rootURL + '/users/root/' + UserID + '/visibility?access_token=' + this.access_token).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  editRootUserDetailsVisibility(userId, visibility) {
    return this.http.put(this.commonservice.rootURL + '/users/root/' + userId + '/visibility/' + visibility + '?access_token=' + this.access_token, visibility).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  editRootUserDetails(userId, data) {
    return this.http.put(this.commonservice.rootURL + '/users/root/' + userId + '?access_token=' + this.access_token, data).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getRootconfig(id) {
    return this.http.get(this.commonservice.rootURL + '/users/root/' + id + '/config?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getuserconfig(id) {
    return this.http.get(this.commonservice.rootURL + '/users/' + id + '/config?access_token=' + this.access_token)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  editRootUserConfig(id, data) {
    return this.http.put(this.commonservice.rootURL + '/users/root/' + id + '/configs?access_token=' + this.access_token, data)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  editUserConfig(id, data) {
    return this.http.put(this.commonservice.rootURL + '/users/' + id + '/config?access_token=' + this.access_token, data)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message == "Access is Denied" || errorMessage.message == "Access is denied") {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton:false,
        showCancelButton:false,
        focusConfirm: true,
        type: 'error'
      });
    }else if (errorMessage.status !== 200) {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton:false,
        showCancelButton:false,
        focusConfirm: true,
        type: 'warning'
      });
    }
    return Observable.throw(error || " server error ");
  }


}