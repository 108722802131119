import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {LoginService} from '../login/login.service';
import {SharedService} from './shared.service';
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import {RolesService} from '../setting/UserDetails/roles.service';
import { ProfileService } from './../profiles/profile.service';
import {LoansService} from '../loans/loans.service';
import {UserService} from '../setting/UserDetails/user.service';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        NgbModule,
        TranslateModule,
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective
    ],
    providers: [
        RolesService,
        LoginService,
        SharedService,
        ProfileService,
        LoansService,
        UserService
    ],
   
})
export class SharedModule { }
