import { Routes, PreloadAllModules } from "@angular/router";
export const Full_ROUTES: Routes = [
  {
    path: "Dashboard",
    loadChildren: "./dashboard/dashboard.module#DashboardModule",
  },
  {
    path: "calendar",
    loadChildren: "./calendar/calendar.module#CalendarsModule",
  },
  {
    path: "agent-tracking",
    loadChildren: "./agent-tracking/agent-tracking.module#AgentTrackingModule",
  },
  {
    path: "client-overview",
    loadChildren:
      "./client-overview/client-overview.module#ClientOverviewModule",
  },
  {
    path: "deploy-log",
    loadChildren: "./decentralised/load-deploy.module#LoadDeployModule",
  },
  {
    path: "Branches",
    loadChildren: "./branches/branches.module#BranchesModule",
  },
  {
    path: "Clients",
    loadChildren: "./profiles/profiles.module#ProfilesModule",
  },
  {
    path: "Group",
    loadChildren: "./group/group.module#GroupModule",
  },
  {
    path: "Setting",
    loadChildren: "./setting/setting.module#SettingModule",
    data: {
      preload: false,
      delay: false,
    },
  },
  {
    path: "Settings",
    loadChildren: "./settings/settings.module#SettingsModule",
    data: {
      preload: true,
      delay: true,
    },
  },
  {
    path: "Payroll",
    loadChildren: "./payroll/payroll.module#PayrollModule",
  },
  {
    path: "DeleteContract",
    loadChildren:
      "./delete-contract/delete-contract.module#DeleteContractModule",
  },

  {
    path: "OnBoard",
    loadChildren: "./on-board/on-board.module#OnBoardModule",
  },
  {
    path: "LinkCard",
    loadChildren: "./link-card/link-card.module#LinkCardModule",
  },
  {
    path: "Portfolio",
    loadChildren: "./portFolio/portFolio.module#PortFolioModule",
  },
  {
    path: "LinkPortfolio",
    loadChildren: "./linkPortfolio/linkPortfolio.module#LinkPortFolioModule",
  },
  {
    path: "Reporting",
    loadChildren: "./reporting/reporting.module#ReportingModule",
  },
  {
    path: "Analysis",
    loadChildren: "./analysis/analysis.module#AnalysisModule",
  },
  {
    path: "Teller",
    loadChildren: "./collections/collections.module#CollectionsModule",
  },
  {
    path: "Approval",
    loadChildren: "./approval/approval.module#ApprovalModule",
  },
  {
    path: "Receipt",
    loadChildren: "./receipt/receipt.module#ReceiptModule",
  },
  {
    path: "ReceiptApproval",
    loadChildren:
      "./receipt-approval/receipt-approval.module#ReceiptApprovalModule",
  },
  {
    path: "LoadDailySummary",
    loadChildren:
      "./load-daily-summary/load-daily-summary.module#LoadDailySummaryModule",
  },
  {
    path: "ReassignPortfolio",
    loadChildren:
      "./reassign-portfolio/reassign-portfolio.module#ReassignPortfolioModule",
  },
  {
    path: "CasperBlockChain",
    loadChildren: "./casper-block-chain/casper.module#CasperModule",
  },
  {
    path: "Loans",
    loadChildren: "./loans/loans.module#LoansModule",
  },

  {
    path: "Borrowings",
    loadChildren: "./borrowings/borrowings.module#BorrowingsModule",
  },
  {
    path: "Synchronization",
    loadChildren:
      "./synchronization/synchronization.module#SynchronizationModule",
  },
  {
    path: "DocumentStorage",
    loadChildren:
      "./document-storage/document-storage.module#DocumentStorageModule",
  },
  {
    path: "Accounting",
    loadChildren: "./account/account.module#AccountModule",
  },
  {
    path: "BulkDisbursement",
    loadChildren:
      "./bulk-disbursement/bulk-disbursement.module#BulkDisbursementModule",
  },
  {
    path: "BulkRepayment",
    loadChildren: "./bulk-repayment/bulk-repayment.module#BulkRepaymentModule",
  },
  {
    path: "DelinquencyManagement",
    loadChildren:
      "./delinquency-management/delinquency-management.module#DelinquencyManagementModule",
  },
  {
    path: "FundingLine",
    loadChildren: "./funding-line/funding-line.module#FundingLineModule",
  },
  {
    path: "product-factory",
    loadChildren:
      "./product-factory/product-factory.module#ProductFactoryModule",
  },
  {
    path: "legal-agreement",
    loadChildren:
      "./legal-agreement/legal-agreement.module#LegalAgreementModule",
  },
];
