
import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AppService } from '../app.service';
import { LoginService } from '../login/login.service';
import swal from 'sweetalert2';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { BranchesService } from 'app/branches/branches.service';
import { PortFolioService } from 'app/portFolio/portFolio.service';
import { debounce } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable()
export class ProfileService {
  public defaultStartPage:string;
  public profileId: any;
  public profileFormId: any;
  public branches: any;
  public profileFormData: any;
  public access_token: string;
  public baseUrl: string;
  public treeLevel;
  public baseLink: string;
  private headers = new Headers({ 'content-type': 'application/json' })
  private options = new RequestOptions({ headers: this.headers });

  public profileBaseUrl: string;

  constructor(private _http: Http,private httpClient:HttpClient,private commonservice: AppService, private portfolioService: PortFolioService,
    private loginService: LoginService, private branchService: BranchesService) {
    this.baseUrl = this.loginService.urlNumber() + ":" + this.loginService.portnumber() + '/ProfileService';
    this.access_token = loginService.access_tokenMethod();
    this.baseLink = this.profilePort();
  }

  profilePort() {
    return this.profileBaseUrl = this.loginService.urlNumber() + ":" + this.loginService.portnumber() + "/ProfileService";
  }

  createProfile(selectProfile) {
    return this._http.post(this.profilePort() + '/profiles?access_token=' + this.access_token, selectProfile, this.options).map((res: Response) => res.json()).catch(this.errorHandler);;
  }

  createProfileform(selectProfileForm) {
    return this._http.post(this.profilePort() + '/profiles/forms?access_token=' + this.access_token, selectProfileForm, this.options).map((res: Response) => res.json());
  }

  provideLinkMenuToRole(roleId) {
    return this._http.get(this.commonservice.menuURL + '/provideLinkMenuToRole?access_token=' + this.access_token + (roleId ? '&roleId=' + roleId : '&roleId='), this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideSelectedMenu(roleId) {
    return this._http.get(this.commonservice.menuURL + '/provideSelectedMenu?access_token=' + this.access_token + (roleId ? '&roleId=' + roleId : '&roleId='), this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideUserRolesByUser(userID) {
    return this._http.get(this.commonservice.roleURL + '/users/' + userID + '/roles?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getLogo() {
    return this._http.get(this.profilePort() + '/logos/' + this.loginService.rootUserID + '/personal?access_token=' + this.access_token).map((res: Response) => res.json());
  }

  deleteLogo(fileName, rootUserID) {
    return this._http.delete(this.profilePort() + '/deleteLogo/' + this.loginService.rootUserID + '/personal/' + fileName + '?access_token=' + this.access_token).map((res: Response) => res.json());
  }

  addLogo(formData, rootUserID) {
    return this._http.post(this.profilePort() + '/upload/logo/' + this.loginService.rootUserID + '/personal?access_token=' + this.access_token, formData).map((res: Response) => res.json());
  }

  searchBranchName(branchName) {
    return this._http.get(this.portfolioService.portfolioport() + '/hierarchySearch?access_token=' + this.access_token + (branchName ? '&branchName=' + branchName : ''), this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  addProductTemplateFile(productId, frmData) {
    return this._http.post(this.profilePort() + "/upload/productTemplate?access_token=" + this.access_token + '&productId=' + productId, frmData).map((res: Response) => { return res.json() });
  }

  getProductTemplateFile(productId) {
    return this._http.get(this.profilePort() + "/product/" + productId + "?access_token=" + this.access_token, this.options).map((res: Response) => { return res.json() });
  }

  provideProfileFormByProfileId(profileID) {
    return this._http.get(this.profilePort() + "/profiles/forms?access_token=" + this.access_token + (profileID ? '&formId=' + profileID : ''), this.options).map((res: Response) => { return res.json() });
  }

  provideProfile() {
    return this._http.get(this.profilePort() + '/profiles?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  provideProfilePagination(pgNum, records) {
    return this._http.get(this.profilePort() + "/profiles?pageNumber=" + pgNum + "&numberOfRecords=" + records + "&access_token=" + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  // provideProfilePagination(pgNum, records) {
  //   const headers = new HttpHeaders({ 'content-type': 'application/json' }).set('X-XSRF-TOKEN','adsss').set('Accept','application/json')
  //   const options = { headers: headers}
  //   return this.httpClient.get(this.profilePort() + "/profiles?pageNumber=" + pgNum + "&numberOfRecords=" + records + "&access_token=" + this.access_token, { headers: headers, observe: 'response' }).map((response) => response)
  //     .catch(this.errorHandler);
  // }

  provideProfileById(ProfileID) {
    return this._http.get(this.profilePort() + "/profiles/" + ProfileID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  filterProfiles(filterData) {
    return this._http.get(this.profilePort() + "/searchProfiles?" + (filterData.Branch ? "&branchId=" + filterData.Branch + "&" : '') + (filterData.Identifier ? "&identifier=" + filterData.Identifier : '')
      + (filterData.Hierarchy ? "&Hierarchy=" + filterData.Hierarchy : '') + (filterData.Name ? "&name=" + filterData.Name : '') + (filterData.profileID ? "&profileId=" + filterData.profileID : '') + (filterData.linkedProfileID ? "&linkedProfileId=" + filterData.linkedProfileID : '')
      + (filterData.NationalID ? "&nationalId=" + filterData.NationalID : '') + "&access_token=" + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  filterProfilesWithPagination(filterData, pgNo, noOfRecords) {
    return this._http.get(this.profilePort() + "/searchProfiles?" + (filterData.Branch ? "&branchId=" + filterData.Branch + "&" : '') + (filterData.Identifier ? "&identifier=" + filterData.Identifier : '')
      + (filterData.Hierarchy ? "&Hierarchy=" + filterData.Hierarchy : '') + (filterData.Name ? "&name=" + filterData.Name : '') + (filterData.profileID ? "&profileId=" + filterData.profileID : '') + (filterData.linkedProfileID ? "&linkedProfileId=" + filterData.linkedProfileID : '')
      + (filterData.NationalID ? "&nationalId=" + filterData.NationalID : '') + (filterData.mobileNumber ? "&mobileNumber=" + filterData.mobileNumber : '') + "&access_token=" + this.access_token + '&pageNumber=' + pgNo + '&numberOfRecords=' + noOfRecords, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  deleteProfile(profileId) {
    return this._http.delete(this.profilePort() + '/profiles/' + profileId + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  updateProfile(selectProfile, ProfileID) {
    return this._http.put(this.profilePort() + '/profiles/' + ProfileID + '?access_token=' + this.access_token, selectProfile, this.options).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  updateProfileForm(selectProfileForm, profileFormId) {
    return this._http.put(this.profilePort() + '/profiles/forms/' + profileFormId + '?access_token=' + this.access_token, selectProfileForm, this.options).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  provideProfileFormById(profileFormId) {
    return this._http.get(this.profilePort() + "/profiles/forms/" + profileFormId + '?access_token=' + this.access_token, this.options).map((response: Response) => { return response.json() })
      .catch(this.errorHandler);
  }

  deleteProfileForm(profileFormId) {
    return this._http.delete(this.profilePort() + '/profiles/forms/' + profileFormId + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  provideAllCountry() {
    return this._http.get(this.profilePort() + '/country?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  ProviceStateByCountryID(countryID) {
    return this._http.get(this.profilePort() + '/state/' + countryID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  ProviceCityByStateID(stateID) {
    return this._http.get(this.profilePort() + '/city/' + stateID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranches(branchLevel) {
    return this._http.get(this.branchService.branchPort() + '/getBranchByBranchLevel/' + branchLevel + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getAllBranches() {
    return this._http.get(this.profilePort() + '/branches/hierarchy?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getAllBranchNames() {
    return this._http.get(this.portfolioService.portfolioport() + '/hierarchySearch?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getAllBranches1(branchid) {
    return this._http.get(this.profilePort() + '/branches/hierarchy?access_token=' + this.access_token + '&branchid=' + branchid, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchGroup(branchID) {
    return this._http.get(this.profilePort() + '/getTreeLevelBranchByBranchId/' + branchID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchByID(branchID) {
    return this._http.get(this.profilePort() + '/provideBranch/' + branchID + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchesTree() {
    return this._http.get(this.branchService.branchPort() + '/branches/treeLevel?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getAllBranchesTree() {
    return this._http.get(this.branchService.branchPort() + '/getAllTreeLevelBranches?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  searchApprovalProfiles(profileids, approved) {
    return this._http.get(this.profilePort() + '/searchapprovalProfiles?profileIds=' + profileids + '&access_token=' + this.access_token + (approved ? '&approved=' + approved : '&approved=')).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getTreeHIerarchy() {
    return this._http.get(this.profilePort() + '/branches/treeHierarchy?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getProfiles() {
    return this._http.get(this.profilePort() + '/profiles?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  uploadFilesToProfile(pID, type, value) {
    return this._http.post(this.baseUrl + "/upload/files/" + pID + "/" + type + "?access_token=" + this.access_token, value).map((res: Response) => res.json())
      .catch(this.errorHandler);

  }

  editWorkflowPicture(picture, workflowId) {
    return this._http.post(this.baseUrl + "/upload/workflowPicture/" + workflowId + "?access_token=" + this.access_token, picture).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  getFileList(profID, type) {
    return this._http.get(this.baseUrl + '/files/' + profID + '/' + type + '?access_token=' + this.access_token).map((response: Response) => response.json());
  }

  getFile(profID, type, fileName) {
    return this._http.get(this.baseUrl + '/downloadFile/' + profID + '/' + type + '/' + fileName + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  createProfileAsw(selectProfile) {
    return this._http.post(this.loginService.PROFILE_URL + '/profiles?access_token=' + this.loginService.access_token, selectProfile).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  updateProfileAsw(profileDetails, profID) {
    return this._http.put(this.loginService.PROFILE_URL + '/profiles/' + profID + '?access_token=' + this.loginService.access_token, profileDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  addLinkedProfileToProfile(profID, linkedProfID) {
    return this._http.put(this.profilePort() + '/profile/' + profID + '/linkedProfile/' + linkedProfID + '?access_token=' + this.access_token, this.options).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  provideProfileFormByFormID(profileID) {
    return this.httpClient
      .get(`${this.profilePort()}/profiles/forms/${profileID}`)
      .map((res: HttpResponse<any>) => res);
  }
  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message == "Access is Denied" || errorMessage.message == "Access is denied") {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: 'error'
      });
    } else if (errorMessage.status !== 400) {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: 'error'
      });
    }
    return Observable.throw(error || " server error ");
  }

}
