import { TourConfig } from "./../../tour/tour.config";
import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "../../app.service";
import { SharedService } from "../shared.service";
import swal from "sweetalert2";
import { LoginService } from "../../login/login.service";
import { ProfileService } from "./../../profiles/profile.service";
import { LoansService } from "../../loans/loans.service";
import { UserService } from "../../setting/UserDetails/user.service";
import { BranchesService } from "app/branches/branches.service";
import { I18NService } from "app/I18NService/i18-n.service";
import { ToastrService } from "ngx-toastr";
import introJs from "intro.js";

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { passwordMatchValidator } from "app/validator/passwordMatch";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  introJs = introJs();

  currentLang = "en";
  pass;
  border;
  public items: any;
  public allBranches: any;
  public map = new Map();
  conPass;
  currentUser: any;
  toggleClass = "ft-maximize";
  tableColName: any;
  hierarchy;
  clientID;
  nationalID;
  clientName;
  imageUrl;
  menu = [];
  entityName: any;
  clearToken;
  public changePassForm: FormGroup;
  public passwordPattern: string =
    "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&-+=()])(?=\\S+$).{8,20}$";

  private steps = [];

  private tourJson: any;

  constructor(
    public translateService: TranslateService,
    private i18nService: I18NService,
    private toastr: ToastrService,
    private loansService: LoansService,
    private userService: UserService,
    private profileService: ProfileService,
    private modalService: NgbModal,
    public appService: AppService,
    public fb: FormBuilder,
    private httpClient: HttpClient,
    public sharedService: SharedService,
    public loginService: LoginService,
    private branchService: BranchesService
  ) {
    this.translateService.setDefaultLang("en");
    this.currentLang = "en";
    this.translateService.use(localStorage.getItem("lang") || "en");
    $(document).ready(() => {
      this.loginService.changeTableTheme();
    });
  }

  ngOnInit() {
    this.httpClient.get("assets/tour/tour-config.json").subscribe((res) => {
      this.tourJson = res;
    });

    this.changePassForm = this.fb.group(
      {
        "Old Password": ["", Validators.required],
        "New Password": [
          "",
          [Validators.required, Validators.pattern(this.passwordPattern)],
        ],
        "Confirm Password": ["", Validators.required],
      },
      {
        validator: passwordMatchValidator("New Password", "Confirm Password"),
      }
    );
    this.loginService.access_token = localStorage.getItem("access_token");
    this.loginService.access_token_asw = localStorage.getItem("solitx_token");
    this.loginService.loginProcessing = false;

    if (this.loginService.tokenExpireTime) {
      this.autoLogout(this.loginService.tokenExpireTime * 1000);
    }

    this.branchService.getBranchesTree1().then((hierarchy) => {
      this.items = hierarchy["data"]["branches"]["structure"];
      this.items.splice(0, 0, {
        branch_name: "Hierarchy",
        current_branch_id: "0",
        children: [],
      });
      this.loginService.hierarchy = this.items;
    });

    if (!this.loginService.map || !this.loginService.hierarchy) {
      this.profileService.getAllBranchNames().subscribe((br) => {
        this.allBranches = br["data"]["portfolio"];
        this.loginService.allBranchId = br;
        this.allBranches.forEach((key_val) => {
          this.map.set(key_val.branchid, key_val.branch_name);
        });
        this.loginService.map = this.map;
      });
      this.loansService.getProducts().subscribe(({data}) => {
        this.loginService.productsData = data;
        this.userService.rootuserId=data[0].rootUserID;
      });
    }

    this.userService.getUser().subscribe((users) => {
      this.loginService.usersData = users["data"]["users"];
      this.loginService.triggerUserEvent(users["data"]["users"]);
    });

    this.profileService.getAllBranchesTree().subscribe((hierarchy) => {
      this.loginService.treeLevelBranches = hierarchy["data"]["branches"];
      this.loginService.treeLevelBranches.splice(0, 0, {
        branch_name: "Hierarchy",
        current_branch_id: "0",
        children: [],
      });
    });
  }

  // startTour() {
  //   this.steps = TourConfig.getTourSteps(
  //     this.loginService.componentTourName,
  //     this.tourJson
  //   );

  //   this.introJs
  //     .setOptions({
  //       steps: this.steps,
  //       exitOnEsc: false,
  //       overlayOpacity: 0.6,
  //       // showProgress: true,
  //       skipLabel: "Skip",
  //       disableInteraction: false,
  //       prevLabel: "< Back",
  //       nextLabel: "Next >",
  //       doneLabel: "Done!",
  //       // showBullets: true,
  //       showButtons: true,
  //       exitOnOverlayClick: false,
  //       showStepNumbers: true,
  //       keyboardNavigation: true,
  //     })
  //     .start();

  //   this.introJs.oncomplete(() => {
  //     this.introJs.exit(true);
  //   });

  // }

  /**
   * Starts a guided tour using the Intro.js library.
   * @returns {void} Indicates that the function does not return a value.
   */
  startTour() {
    // Fetch the tour steps from the provided tour configuration using the loginService's component tour name and tour JSON.
    this.steps = TourConfig.getTourSteps(
      this.loginService.componentTourName,
      this.tourJson
    );

    // Configure the Intro.js options for the tour.
    this.introJs
      .setOptions({
        steps: this.steps,
        exitOnEsc: false,
        overlayOpacity: 0.6,
        skipLabel: "Skip",
        disableInteraction: false,
        prevLabel: "< Back",
        nextLabel: "Next >",
        doneLabel: "Done!",
        exitOnOverlayClick: false,
        showStepNumbers: true,
        keyboardNavigation: true,
      })
      .start();

    // Define an action when the tour is completed.
    this.introJs.oncomplete(() => {
      this.introJs.exit(true);
    });
  }

  changeLanguage(language: string) {
    localStorage.setItem("lang", language);
    this.currentLang = language;
    this.i18nService.changeLocale(localStorage.getItem("lang") || "en");
  }

  control(name): FormControl {
    return this.changePassForm.get(name) as FormControl;
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
      document.documentElement.requestFullscreen();
    } else {
      this.toggleClass = "ft-maximize";
      document.exitFullscreen();
    }
  }

  functionLogout() {
    // this.loginService.logoutToast.next('logOut');
    localStorage.setItem("lang", "");
    window.open("/", "_self");
  }

  autoLogout(expiredTokenTime) {
    this.loginService.clearToken = setTimeout(() => {
      localStorage.setItem("tokenTime", this.loginService.clearToken);
      this.functionLogout();
    }, expiredTokenTime);
  }

  confirmPass(event) {
    if (this.pass == event) {
      $("#conPass").attr("style", "border-color:rgb(36, 199, 177) !important");
    } else
      $("#conPass").attr("style", "border-color:rgba(255,0,0,0.8) !important");
  }

  passChange(modal) {
    const openModal = this.modalService.open(modal);
    openModal.result.then(
      () => {},
      () => {
        this.changePassForm = this.fb.group(
          {
            "Old Password": ["", Validators.required],
            "New Password": [
              "",
              [Validators.required, Validators.pattern(this.passwordPattern)],
            ],
            "Confirm Password": ["", Validators.required],
          },
          {
            validator: passwordMatchValidator(
              "New Password",
              "Confirm Password"
            ),
          }
        );
      }
    );
  }

  changePassword() {
    if (
      this.appService.userDetails.userID ==
      this.appService.userDetails.rootUserID
    ) {
      this.sharedService
        .changeRootUserPassword(this.changePassForm.value)
        .subscribe(
          () => {
            swal({
              title: "Password Updated!",
              text: "Please Re-login. Logging Out...",
              type: "success",
              showConfirmButton: false,
            });
            setTimeout(function () {
              window.open("/", "_self");
            }, 3000);
          },
          (error) => {
            let errorMessage = JSON.parse(error._body);
            if (errorMessage.message) {
              swal({
                title: "Please Check",
                text: errorMessage.message,
                confirmButtonColor:this.appService.themeColor,
                type: "error",
              });
            }
          }
        );
    } else {
      this.sharedService
        .changeUserPassword(this.changePassForm.value)
        .subscribe(() => {
          swal({
            title: "Password Updated!",
            text: "Please Re-login. Logging Out...",
            type: "success",
            showConfirmButton: false,
          });
          setTimeout(function () {
            window.open("/", "_self");
          }, 3000);
        }, (error) => {
          let errorMessage = JSON.parse(error._body);
          if (errorMessage.message) {
            swal({
              title: "Please Check",
              text: errorMessage.message,
              confirmButtonColor:this.appService.themeColor,
              type: "error",
            });
          }
        });
    }
  }
}
