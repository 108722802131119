import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { TreeNode } from './treenode';
import swal from 'sweetalert2';

import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { LoginService } from '../login/login.service';
@Injectable({
  providedIn: 'root',

})
export class BranchesService {
  public access_token: any;
  public branchId: any;
  public subBranchId: any;
  public editBranchId: any;
  public branchBaseUrl: any;
  private headers = new Headers({ 'content-type': 'application/json' });
  private options = new RequestOptions({ headers: this.headers });
  constructor(private http: Http, private loginService: LoginService, private commonservice: AppService) {

    this.access_token = loginService.access_tokenMethod();
  }

  branchPort() {
    // return this.branchBaseUrl=this.loginService.urlNumber()+":"+this.loginService.portnumber()+"/mff/api";
    return this.branchBaseUrl = this.loginService.urlNumber() + ":" + this.loginService.portnumber() + "/BranchService";

    //  return this.profileBaseUrl="http://188.214.128.73:"+this.loginService.portnumber()+"/mff/api";
    //   return this.profileBaseUrl="http://188.214.128.73:6000/mff/api";
    // return this.profileBaseUrl="http://localhost:"+this.loginService.portnumber()+"/mff/api";
    //return this.branchBaseUrl = "http://localhost:6003/mff/api";
    /// return this.profileBaseUrl="http://localhost:5151/MFFService";
  }
  getBranch() {
    return this.http.get(this.branchPort() + '/provideBranch?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getBranches() {
    console.log("access_token:" + this.access_token)
    return this.http.get(this.branchPort() + '/branches/treeHierarchy?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getBranchByLevel(branchLevel) {
    return this.http.get(this.branchPort() + '/getBranchByBranchLevel/' + branchLevel + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchesTree() {
    return this.http.get(this.branchPort() + '/branches/treeLevel?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchesTree1() {
    return this.http.get(this.branchPort() + '/branches/treeLevel?access_token=' + this.access_token, this.options).toPromise().then(res => res.json()).catch(this.errorHandler);
  }

  // getUserInfo(){
  //   return this.http.get(this.commonservice.rootURL+'/users/root?access_token='+this.access_token).toPromise().then(res=>res.json()).catch(this.errorHandler);
  // }

  getAllBranchesTree() {
    return this.http.get(this.branchPort() + '/getAllTreeLevelBranches?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getSubBranch() {
    // // console.log("serv branchId: "+this.subBranchId);
    return this.http.get(this.branchPort() + '/branches/provideSubBranch/' + this.subBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  updateBranch(editBranchId, editBranch: any) {
    // // console.log('id: '+editBranchId+" json: "+JSON.stringify(editBranchName));
    return this.http.put(this.branchPort() + '/updatebranchName/' + editBranchId + '?access_token=' + this.access_token, editBranch, this.options).map((res: Response) => res.json());
  }
  createBranch(newBranchName: any) {
    // // console.log('new json: '+JSON.stringify(newBranchName));
    return this.http.post(this.branchPort() + '/createBranch?access_token=' + this.access_token, newBranchName, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  addBranch(branchData: any) {
    // // console.log('new json: '+JSON.stringify(newBranchName));
    return this.http.post(this.branchPort() + '/Branches?access_token=' + this.access_token, branchData, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  delBranch(delBranchId) {
    return this.http.delete(this.branchPort() + '/deleteBranchById/' + delBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchGroup(branchID) {
    return this.http.get(this.branchPort() + '/getTreeLevelBranchByBranchId/' + branchID + '?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getBranchByID(branchID) {
    return this.http.get(this.branchPort() + '/provideBranch/' + branchID + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  // SUB BRANCH SERVICES
  updateSubBranch(editSubBranchId, editSubBranchData: any) {
    // // console.log('id: '+editSubBranchId+" json: "+JSON.stringify(editSubBranchData));
    return this.http.put(this.branchPort() + '/updateSubBranch/' + editSubBranchId + '?access_token=' + this.access_token, editSubBranchData, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  createSubBranch(newSubBranchData: any) {
    // // console.log('new json: '+JSON.stringify(newSubBranchData));
    return this.http.post(this.branchPort() + '/createSubBranch?access_token=' + this.access_token, newSubBranchData, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }
  delSubBranch(delSubBranchId) {
    return this.http.delete(this.branchPort() + '/deleteSubBranch/' + delSubBranchId + '?access_token=' + this.access_token).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }


  errorHandler(error) {

    let errorMessage = JSON.parse(error._body)
    console.log("errorMessage:" + JSON.stringify(errorMessage));
    if (errorMessage.message == "Access is Denied" || errorMessage.message == "Access is denied") {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: 'error'
      });
    }
    else if (errorMessage.status !== 200) {
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h5 class="swal-msg-font swal-text-font">${errorMessage.message}</h5>`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: true,
        type: 'error'
      });
     
    }

    return Observable.throw(error || " server error ");
  }
}
