import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import 'rxjs/add/operator/map'; 
import {LoginService} from '../login/login.service';
import { AppService } from '../app.service';
import {Observable} from 'rxjs/Observable';
import swal from 'sweetalert2';
import { ProfileService } from 'app/profiles/profile.service';



 @Injectable()
export class SharedService {
  public editUserDetails:any;
  public access_token:any;
  public editUserID:any;
 public userFormTamplateId:any;
 public access_token_asw:any;


  constructor (
    private http: Http,
    private loginService:LoginService,private commonservice:AppService
  ) {
   this.access_token=loginService.access_tokenMethod();
   console.log("enterd:")
   this.access_token_asw = loginService.accessTokenAsw();
  }
  changeRootUserPassword(passwordCreds){
    return this.http.post(this.commonservice.rootURL+`/updateRootPassword?access_token=`+this.access_token, passwordCreds).map((res:Response) => res.json())
    .catch(this.errorHandler) ;
  }
  changeUserPassword(passwordCreds){
    return this.http.post(this.commonservice.rootURL+`/changeUserPassword?access_token=`+this.access_token, passwordCreds).map((res:Response) => res.json())
    .catch(this.errorHandler) ;
  }
  
  provideChartOfAccountss() {
    return this.http.get(this.loginService.ACCOUNT_URL + '/accounting/coa?access_token=' + this.access_token,)
      .map((response: Response) => response.json()).catch(this.errorHandler);
  }

  getUserInfo(){
    return this.http.get(this.commonservice.rootURL+'/users/root?access_token='+this.access_token).map((res: Response) => res.json());
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    console.log("errorMessage:"+JSON.stringify(errorMessage));
  
    if(errorMessage.status == 400){
      swal("Sorry!", errorMessage.message, "error");
    
    }
    else if(errorMessage.status == 404){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.status == 401){
      swal("Sorry!", errorMessage.message, "error");
    }
    else if(errorMessage.message=="Access is Denied" || errorMessage.message=="Access is denied"){
      swal("Sorry!", errorMessage.message, "error");
    }
     
    return Observable.throw(error || " server error ");
  }
}