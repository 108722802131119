import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { LoginService } from '../login/login.service';
import { AppService } from '../app.service';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})

export class OnBoardService {
  linkProfileCount = 0;
  public profileId: any;
  public verifyDetails: any;
  public onboardDetails: any
  public workflowTemplateID = 0;
  public access_token: any;
  public workFlowID: any;
  public workFlowName: any;
  public workFlowTemplateDetails: any;
  private headers = new Headers({ 'content-type': 'application/json' });
  private options = new RequestOptions({ headers: this.headers });

  private headers1 = new Headers({ 'content-type': 'multipart/form-data' });
  private options1 = new RequestOptions({ headers: this.headers1 });
  public profileBaseUrl: any;
  public cashFlowDetailsData: any;
  public creditBureauData: any;
  public creditScoreData: any;
  public profileStatus: any;
  public detailsId: any
  public stepIndex: any = 0;
  public linkedProfiles = [];

  reportURL;
  profileData: any;
  public saved: boolean = false
  verifyFromInside: boolean = false;
  aadhaarDetails = []
  constructor(private _http: Http, private loginService: LoginService, private appService: AppService) {
    this.access_token = loginService.access_tokenMethod();
    this.reportURL = this.appService.mffURL + ':8686/highmark/getHighmarkVerification/'
    this.aadhaarDetails = []
  }

  profilePort() {
    return this.profileBaseUrl = this.appService.mffURL + ":" + this.loginService.portnumber() + "/WorkflowService";
  }

  createWorkflow(workFlowData) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.profilePort() + '/workflows?access_token=' + this.access_token, workFlowData, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  editWorkflowName(workflowID, WorkflowTemplateDetails) {
    return this._http.put(this.profilePort() + '/updateworkflowName/' + workflowID + '?access_token=' + this.access_token, WorkflowTemplateDetails)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowByID(workflowID) {
    return this._http.get(this.profilePort() + '/workflows/' + workflowID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideTemplateAccess(workflowID, templateDetails) {
    return this._http.put(this.profilePort() + '/updateworkflowTemplateflag/' + workflowID + '?access_token=' + this.access_token, templateDetails)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflows() {
    return this._http.get(this.profilePort() + '/workflows?access_token=' + this.access_token, this.options).
      map((response: Response) => response.json()).catch(this.errorHandler);
  }

  deleteWorkflow(workFlowId) {
    return this._http.delete(this.profilePort() + '/workflows/' + workFlowId + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  addWorkflowTemplate(workFlowId, payload) {
    return this._http.put(this.profilePort() + '/addWorkflowTemplate/' + workFlowId + '?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  deleteTemplate(workFlowId, payload) {
    return this._http.put(this.profilePort() + '/removeWorkflowTemplate/' + workFlowId + '?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  changeWorkflowsOrder(workFlowId, payload) {
    return this._http.put(this.profilePort() + '/updateWorkfloworder/' + workFlowId + '?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  createWorkflowTemplate(workFlowTemplate) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.profilePort() + '/workflowTemplates?access_token=' + this.access_token, workFlowTemplate, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowTemplates() {
    return this._http.get(this.profilePort() + '/workflowTemplates?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  deleteWorkflowTemplate(workFlowTemplateId) {
    return this._http.delete(this.profilePort() + '/workflowTemplates/' + workFlowTemplateId + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowTemplatesByID(workFlowTemplateId) {
    return this._http.get(this.profilePort() + '/workflowTemplates/' + workFlowTemplateId + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => { return res.json()['data']['workflow'] }).catch(this.errorHandler);
  }

  verifyAadharID(payload) {
    return this._http.post(this.appService.verifyAadharUrl + '?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => { return res.json() }).catch(this.errorHandler);
  }

  verifyAadharOTP(payload) {
    return this._http.post(this.appService.verifyAadharOTP + '?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => { return res.json() }).catch(this.errorHandler);
  }

  attachWorkflowToProfile(workFlowTemplate) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.post(this.profilePort() + '/workflowToProfiles?access_token=' + this.access_token, workFlowTemplate, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }
  getAllProfilesLinkedToWorkflows() {
    return this._http.get(this.profilePort() + '/workflowsToProfiles?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowTemplateDetails(workFlowID, verified) {
    return this._http.get(this.profilePort() + '/workflow/workflowTemplateDetails/' + workFlowID + '/' + verified + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getSocialResult(profileID) {
    return this._http.get(this.appService.eSignUrl + '/neenar/result/' + profileID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getPovertyResult(templateID) {
    return this._http.get(this.profilePort() + '/pplScore/' + templateID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getAMLResult(payload) {
    return this._http.post(this.appService.thirdpartyurl + '/shufti', payload, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  sendSocialLink(json) {
    return this._http.post(this.appService.thirdpartyurl + '/sendsms?access_token=' + this.access_token, json)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getUnlinkedProfiles(filterData, workFlowID, pageNumber, noOfRecords) {
    return this._http.get(this.profilePort() + '/unlinkedProfiles/' + workFlowID + '?access_token=' + this.access_token + '&pageNumber=' + pageNumber + '&numberOfRecords=' + noOfRecords + (filterData.Identifier ? '&Identifier=' + filterData.Identifier : '') + (filterData.Name ? '&Name=' + filterData.Name : '') + (filterData.NationalID ? '&nationalId=' + filterData.NationalID : '') + (filterData.Hierarchy ? '&Hierarchy=' + filterData.Hierarchy : ''), this.options)
      .map((res: Response) => { return res.json()['data']['workflow'] }).catch(this.errorHandler);
  }

  getLinkedApprovalByProfileID(workFlowID, profileID) {
    return this._http.get(this.profilePort() + '/approvaldetailsByprofileId/' + workFlowID + '/' + profileID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowTemplateByWorkflowProfileID(workFlowProfileId) {
    return this._http.get(this.profilePort() + '/templateDetailsByworkflowProfileId/' + workFlowProfileId + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => { return res.json()['data']['workflow'] }).catch(this.errorHandler);
  }

  getWorkflowTemplateDetails1(workFlowID, verified, pageNumber, noOfRecords, profileids) {
    return this._http.get(this.profilePort() + '/workflow/workflowTemplateDetails/' + workFlowID + '/' + verified + '?' + (noOfRecords ? '&numberOfRecords=' + noOfRecords : '') + (pageNumber ? '&pageNumber=' + pageNumber : '') + (profileids.length > 0 ? '&profileIds=' + profileids : '') + '&access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getWorkflowTemplateDetails2(filterData, workFlowID, pageNumber, noOfRecords) {
    return this._http.get(this.profilePort() + '/onboardFilter?workflowId=' + workFlowID + (noOfRecords ? '&numberOfRecords=' + noOfRecords : '') + (pageNumber ? '&pageNumber=' + pageNumber : '') + (filterData.Hierarchy ? '&Hierarchy=' + filterData.Hierarchy : '') + (filterData.Name ? '&Name=' + filterData.Name : '') + (filterData.Identifier ? '&identifier=' + filterData.Identifier : '') + (filterData.NationalID ? '&nationalId=' + filterData.NationalID : '') + '&access_token=' + this.access_token, this.options)
      .map((res: Response) => {
        return res.json()['data']['workflow']
      }).catch(this.errorHandler);
  }


  getWorkflowTemplateDetailsByID(workFlowID) {
    return this._http.get(this.profilePort() + '/workflowTemplateDetails/' + workFlowID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => { return res.json()['data']['workflow'] }).catch(this.errorHandler);
  }

  getTemplateID(detailsID) {
    return this._http.get(this.profilePort() + '/workflowTemplateDetails/' + detailsID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  updateScoreme(payload) {
    return this._http.post(this.appService.thirdpartyurl + '/upload/bankStatement?access_token=' + this.access_token,payload)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  generateReportUrl(referenceId) {
    return this._http.get(this.appService.thirdpartyurl + '/generateReportUrl?access_token=' + this.access_token+'&referenceId='+referenceId,this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  saveScoremeReport(requestParams) {
    return this._http.get(`${this.appService.thirdpartyurl}/download/report?profileId=${requestParams.profileId}&referenceId=${requestParams.referId}&access_token=${this.access_token}`,this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }


  updateWorkflowTemplateDetails(WorkflowTemplateDetailsID, WorkflowTemplateDetails) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.put(this.profilePort() + '/workflowTemplateDetails/' + WorkflowTemplateDetailsID + '?access_token=' + this.access_token, WorkflowTemplateDetails, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  verifyWorkflowTemplateDetails(WorkflowTemplateDetails) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.put(this.profilePort() + '/workflowTemplateDetails/verify?access_token=' + this.access_token, WorkflowTemplateDetails, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getCreditReport(templateID) {
    return this._http.get(this.reportURL + templateID)
      .map(res => {
        const contentType = res.headers.get('Content-type');
        if (contentType == 'application/json') {
          return res.json();
        } else if (contentType == 'text/html') {
          return res.text();
        }
      })
  }

  updateProfileStatus(WorkflowTemplateDetailsID, profileStatus) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this._http.put(this.profilePort() + '/workflowsToProfiles/' + WorkflowTemplateDetailsID + '?access_token=' + this.access_token, profileStatus, options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  updateWorkFlowTemplate(WorkflowTemplateID, profileStatus) {
    return this._http.put(this.profilePort() + '/workflowTemplates/' + WorkflowTemplateID + '?access_token=' + this.access_token, profileStatus, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  changeWorflowTemplatStatus(templateID, status) {
    return this._http.put(this.profilePort() + '/updateTemplateflag/' + templateID + '?access_token=' + this.access_token, status, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }
  changeWorflowStatus(workflowId, status) {
    return this._http.put(this.profilePort() + '/workflows/' + workflowId +'/status?access_token=' + this.access_token, status, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  autoVerifyTemplate(templateID, status) {
    return this._http.put(this.profilePort() + '/autoVerify/template?access_token=' + this.access_token+'&templateId='+templateID+'&flag='+status, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  approveProfiles(WorkflowDetailsID) {
    return this._http.put(this.profilePort() + '/approvalProfile/' + WorkflowDetailsID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.text()).catch(this.errorHandler);
  }

  errorHandler(error) {
    this.linkProfileCount = 0;
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message) {
      this.linkProfileCount = 0;
      let msg = errorMessage.message.hasOwnProperty('response_message') ? errorMessage.message['response_message'] : errorMessage.message.hasOwnProperty('response_message') ? errorMessage.message['response_message'] : errorMessage.message
      swal(
        {
          title: "Sorry!",
          text: msg,
          type: 'warning',
          showConfirmButton: false,
          showCloseButton: true
        });
    }
    return Observable.throw(error || " server error ");
  }

}
