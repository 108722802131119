// Represents a step in the tour with specific information about the tour step.
export interface Tour {
  title: string; // Title of the tour step.
  element: string; // DOM element selector that the step is associated with.
  intro: string; // Introduction text or instructions for the step.
  position?: string; // Optional: Positioning of the tour step (e.g., "top", "bottom").
}

// Class responsible for managing tour configuration and steps.
export class TourConfig {
  // Retrieves tour steps for a specified component from the given tour configuration.
  public static getTourSteps(
    component: string, // The component name for which to fetch tour steps.
    tourConfig: any // The array containing tour configuration objects.
  ): Tour[] {
    // Find the tour configuration object for the specified component.
    const config = tourConfig.find((item) => item.component === component);

    // If a valid configuration is found, return its steps; otherwise, return an empty array.
    return config ? config.steps : [];
  }
}
