import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { AppService } from '../app.service';
import swal from 'sweetalert2';



@Injectable({
  providedIn: 'root'
})
export class PortFolioService {

  private headers = new Headers({ 'content-type': 'application/json' });
  private options = new RequestOptions({ headers: this.headers });
  public access_token: any;
  public portfolioUrl: any;

  constructor(private http: Http, private loginService: LoginService, private commonservice: AppService, ) {
    this.access_token = loginService.access_tokenMethod();
  }


  portfolioport() {
    return this.portfolioUrl = this.loginService.urlNumber() + ":" + this.loginService.portnumber() + "/PortfolioService";
  }

  getActiveSummary() {
    return this.http.get(this.portfolioport() + '/dailySummary?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getLinkedPorfolios() {
    return this.http.get(this.portfolioport() + '/getLinkedportfolio?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  validateTeller(contractid, approved) {
    return this.http.get(this.portfolioport() + '/tellerFiltersReport?access_token=' + this.loginService.access_token + '&contractuuid=' + contractid + '&repayment=' + approved, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getContractReceiptApproval(filterData, pageNumber, noOfRecords) {
    return this.http.get(this.portfolioport() + '/receiptApproval?&numberOfRecords=' + noOfRecords + '&pageNumber=' + pageNumber + (filterData.receiptid ? '&receiptId=' + filterData.receiptid : '') + (filterData.loanOfficer ? '&userId=' + filterData.loanOfficer : '') + (filterData.Hierarchy ? '&hierarchy=' + filterData.Hierarchy : '') + (filterData.NationalID ? '&nationalId=' + filterData.NationalID : '') + (filterData.Name ? '&name=' + filterData.Name : '') + (filterData.Identifier ? '&identifier=' + filterData.Identifier : '') + (filterData.from ? '&fromDate=' + filterData.from : '') + (filterData.to ? '&toDate=' + filterData.to : '') + '&access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }



  repaymentsByPrid(prid) {
    return this.http.put(this.portfolioport() + '/Repayments/'+prid+'?access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  deleteContractSolitx(contractids) {
    return this.http.post(this.loginService.TRANSACTION_URL + '/txs/revert/contracts?access_token=' + this.loginService.access_token, contractids, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  
  deleteContractMFF(contractids) {
    return this.http.post(this.portfolioport() + '/deleteContracts?access_token=' + this.loginService.access_token, contractids, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  validateDeleteContractMFF() {
    return this.http.post(this.commonservice.validateURL + '/deleteContract?access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getReconcileBanks() {
    return this.http.get(this.portfolioport() + '/Bank?access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }


  createBanks(payload) {
    return this.http.post(this.portfolioport() + '/Bank?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getFundingLine(filter) {
    return this.http.get(this.portfolioport() + '/fundinglineContracts?access_token=' + this.loginService.access_token + (filter['fundinglineId'] ? '&fundinglineId=' + filter['fundinglineId'] : '') + (filter['pageNumber'] ? '&pageNumber=' + filter['pageNumber'] : '') + (filter['numberOfRecords'] ? '&numberOfRecords=' + filter['numberOfRecords'] : '') + (filter['Hierarchy'] ? '&hierarchy=' + filter['Hierarchy'] : ''), this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getFundingLineList() {
    return this.http.get(this.portfolioport() + '/fundingLine?access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }


  createFundingLineList(payload) {
    return this.http.post(this.portfolioport() + '/fundingLine?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  getFundingNames() {
    return this.http.get(this.portfolioport() + '/fundingNameContracts?access_token=' + this.loginService.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  linkContractsToFund(payload, fundinglineId) {
    return this.http.put(this.portfolioport() + '/updateFundinglineToContracts/' + fundinglineId + '?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  editFundingLineList(payload, fundId) {
    return this.http.put(this.portfolioport() + '/fundingLine/' + fundId + '?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  updateFundingLineList(payload) {
    return this.http.post(this.portfolioport() + '/fundingLine/1?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  editBanks(payload, bankId) {
    return this.http.put(this.portfolioport() + '/Bank/' + bankId + '?access_token=' + this.loginService.access_token, payload, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  putLinkedPortfolios(userID: any, contractID) {
    return this.http.put(this.portfolioport() + '/updateLinkedusertoportfolio/' + userID + '?access_token=' + this.loginService.access_token, contractID, this.options).map((res: Response) => res.json()).catch(this.errorHandler);
  }

  searchPorfolios(filterData, pageNumber, noOfRecords) {
    return this.http.get(this.portfolioport() + '/searchPortfolio?access_token=' + this.loginService.access_token + (pageNumber ? '&pageNumber=' + pageNumber : '') + (noOfRecords ? '&numberOfRecords=' + noOfRecords : '') + (filterData.Identifier ? '&Identifier=' + filterData.Identifier : '') + (filterData.branchId ? '&branchId=' + filterData.branchId : '') + (filterData.Hierarchy ? '&Hierarchy=' + filterData.Hierarchy : '') + (filterData.Name ? '&Name=' + filterData.Name : '') + (filterData.NationalID ? '&nationalId=' + filterData.NationalID : '') + (filterData.profileId ? '&profileId=' + filterData.profileId : '') + (filterData.userId ? '&userId=' + filterData.userId : ''), this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getAllPorfolios( pageNumber, noOfRecords) {
    return this.http.get(this.portfolioport() + '/searchPortfolio?access_token=' + this.loginService.access_token +  '&pageNumber=' + pageNumber  +  '&numberOfRecords=' + noOfRecords , this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }
  getProfiles() {
    return this.http.get(this.portfolioport() + '/profiles?access_token=' + this.access_token, this.options).map((response: Response) => response.json())
      .catch(this.errorHandler);
  }

  /**
   * To catch error
   * @param error 
   */
  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message) {
      swal({ title: "Sorry!", text: errorMessage.message, type: 'warning' });
      swal({
        title: `<h3 class="swal-msg-font swal-title-font">Sorry!</h3>`,
        html: `<h4 class="swal-msg-font swal-text-font">${errorMessage.message}</h4>`,
        showCancelButton: false,
        showConfirmButton:false,
        showCloseButton:true,
        focusConfirm: true,
        type: 'warning'
      })
    }
    return Observable.throw(error || " server error ");
  }
}
