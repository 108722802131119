import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Injectable } from '@angular/core';
import { LoginService } from 'app/login/login.service';
import { lineChartLabels } from '../data/chartjs';

@Injectable()
export class AuthService {
  token: string;

  constructor(public activateRoute: ActivatedRoute, public router: Router, public loginService: LoginService) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    if ((this.loginService.otp2FA && this.loginService.otp2FA != undefined) && this.loginService.otp2FA != '') {
      return true;
    }
    else if ((this.loginService.access_token && this.loginService.access_token !== undefined) && this.loginService.access_token !== '') {
      return true;
    }
    else {
      this.router.navigate(['/Pages/Login'])
      return false;  // not allowed to enter without credential
    }
  }
}
