import { Component, OnInit } from "@angular/core";
import { ROUTES } from "./sidebar-routes.config";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OnBoardService } from "../../on-board/on-board.service";
import { AppService } from "../../app.service";
import { LoginService } from "../../login/login.service";
import { SharedService } from "../shared.service";
import { I18NService } from "app/I18NService/i18-n.service";
import { ProfileService } from "app/profiles/profile.service";
import { parse } from "url";
import { isThisSecond } from "date-fns";
import { Title } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public menutItemsRouter = ROUTES;
  public submenu1 = [];
  public submenuAccounting = [];
  public submenuPayroll = [];
  public submenuAccountingStatements = [];
  public userID: any;
  public chartOfAccName = [];
  onBoardSideBar = [];
  menu = [];
  imageUrl;
  menuNames = [];
  entityName: any;
  showLogo: boolean = false;
  constructor(
    private router: Router,
    private profileService: ProfileService,
    public translate: TranslateService,
    private appService: AppService,
    private onBoardService: OnBoardService,
    private sharedService: SharedService,
    public loginService: LoginService,
    private translateService: TranslateService,
    private i18nService: I18NService,
    private titleService: Title
  ) {
    this.translateService.setDefaultLang("en");
    this.translateService.use(localStorage.getItem("lang") || "en");
  }

  ngOnInit() {
    this.loginService.access_token = localStorage.getItem("access_token");
    let entityLimitSize = 21;
    this.sharedService.getUserInfo().subscribe((users) => {
      this.appService.themeColor =
        users["data"]["users"][0]["profileDetails"]["Theme color"];
      this.loginService.rootDetails = users["data"]["users"];
      this.entityName = users["data"]["users"][0]["profileDetails"][
        "Entity Name"
      ]
        ? users["data"]["users"][0]["profileDetails"]["Entity Name"]
        : "Mobile First Finance";
      this.loginService.entityFullname = this.entityName;
      this.entityName
        ? this.entityName.length > entityLimitSize
          ? (this.entityName =
            this.entityName.slice(0, entityLimitSize - 1) + "...")
          : (this.entityName = this.entityName)
        : "";
      this.loginService.entityName = this.entityName;
      this.titleService.setTitle(this.entityName);

      this.profileService.getLogo().subscribe((details) => {
        this.loginService.logoDetails = details["data"]["documents"];
        this.imageUrl =
          details["data"]["documents"].length > 0
            ? details["data"]["documents"][0]["downloadUrl"].concat(
              "?access_token=" + this.loginService.access_token
            )
            : "";

        this.loginService.logoUrl = this.imageUrl;
        document
          .getElementById("appFavicon")
          .setAttribute("href", this.imageUrl);
        // this.setAppFavicon("/assets/img/down.svg");
      });
    });
    $.getScript("./assets/js/app-sidebar.js");
    this.i18nService.localEvent.subscribe((locale) => {
      this.translateService.use(locale);
      this.menuItems = [];
      this.menuNames = [];
      this.loginService
        .changeLang(localStorage.getItem("lang") || "en")
        .subscribe((data) => {
          let col = JSON.parse(data["_body"]);
          col["sideBarMenu"]["data"][4]["submenu"] = this.onBoardSideBar;
          col["sideBarMenu"]["data"][13]["submenu"][9]["submenu"] =
            this.submenuAccountingStatements;
          if (this.loginService.flag == "USER") {
            this.menu.forEach((title) => {
              for (let i = 0; i < col["sideBarMenu"]["data"].length; i++) {
                if (col["sideBarMenu"]["data"][i].filter == title.menuName) {
                  this.menuNames.push(col["sideBarMenu"]["data"][i]);
                  break;
                }
              }
            });
            this.menuItems = this.menuNames;
          } else if (this.loginService.flag == "ROOTUSER") {
            this.menuItems = col["sideBarMenu"]["data"];
          }
        });
    });
    this.menu = [];
    this.menuNames = [];
    this.menuItems = [];
    let subMenuSmartContract = [];
    if (this.loginService.flag == "USER") {
      this.profileService.provideLinkMenuToRole("").subscribe((menu) => {
        this.menu = menu["data"]["menu"].length > 0 ? menu["data"]["menu"] : [];
        this.loginService.sidebarMenu = this.menu;
        this.onBoardService.getWorkflows().subscribe((data) => {
          let workflows = data["data"]["workflow"].filter(wfData => (wfData.status == "active"))
        this.loginService.listOfWorkflows = workflows;
        this.loginService.workflowsLoaded = true;
        workflows.forEach((element) => {
          if (element.status == 'active') {
            this.submenu1.push({
              path: "/OnBoard/onBoard/" + element["workFlowID"],
              title: element["workFlowName"],
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.onBoardSideBar.push({
              path: "/OnBoard/onBoard/" + element["workFlowID"],
              title: element["workFlowName"],
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
          }
          });
          if (this.menu.length > 0) {
            this.loginService.enableSetting =
              this.menu.filter((menu) => menu.menuName == "Settings").length > 0
                ? false
                : true;
            let submenu2 = [];
            this.menutItemsRouter = [];
            let submenuClients = [];
            this.menutItemsRouter.push({
              path: "/Dashboard/Dashboard",
              title: "Dashboard",
              filter: "Dashboard",
              icon: "ft-home",
              class: "",
              badge: "",
              badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/product-factory/product-factory",
              title: "Product Factory",
              filter: "Product factory",
              icon: "ft-grid",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/legal-agreement/legal-agreement",
              title: "Legal Agreements",
              filter: "Legal agreements",
              icon: "ft-file",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenuClients.push({
              path: "/Clients/Clients",
              title: "List of Clients",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenuClients.push({
              path: "/Branches/Branches",
              title: "Hierarchy",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "",
              title: "Clients",
              filter: "Clients",
              icon: "ft-users",
              class: "has-sub",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: submenuClients,
            });
            this.menutItemsRouter.push({
              path: "/Approval/Approval",
              title: "Approve Client",
              filter: "Approve Client",
              icon: "ft-check-square",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Loans/Loans",
              title: "Accounts",
              filter: "Accounts",
              icon: "ft-briefcase",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Borrowings/Borrowings",
              title: "Borrowings",
              filter: "Borrowings",
              icon: "ft-briefcase",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/LinkCard/LinkCard",
              title: "Link Card",
              filter: "Link Card",
              icon: "ft-credit-card",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/BulkDisbursement/BulkDisbursement",
              title: "Bulk Deals",
              filter: "Bulk Deals",
              icon: "ft-box",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/BulkRepayment/BulkRepayment",
              title: "Bulk Receive",
              filter: "Bulk Receive",
              icon: "ft-box",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/DelinquencyManagement/DelinquencyManagement",
              title: "Delinquency Management",
              filter: "Delinquency Management",
              icon: "ft-alert-octagon",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Portfolio/Portfolio",
              title: "Portfolio",
              filter: "Portfolio",
              icon: "ft-file-text",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/LinkPortfolio",
              title: "Link PortFolio",
              filter: "Link PortFolio",
              icon: "ft-link-2",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/ReassignPortfolio/ReassignPortfolio",
              title: "Reassign Portfolio",
              filter: "Reassign Portfolio",
              icon: "ft-link-2",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Teller/Teller",
              title: "Teller",
              filter: "Teller",
              icon: "icon-drawer",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Receipt/Receipt",
              title: "Receipt",
              filter: "Receipt",
              icon: "ft-file-text",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/ReceiptApproval/ReceiptApproval",
              title: "Receipt Approval",
              filter: "Receipt Approval",
              icon: "ft-file-text",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Reporting/Reporting",
              title: "Reporting",
              filter: "Reporting",
              icon: "ft-file-minus",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/Analysis/Analysis",
              title: "Analysis",
              filter: "Analysis",
              icon: "icon-bar-chart",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/DeleteContract/DeleteContract",
              title: "Exception Handler",
              filter: "Exception Handler",
              icon: "ft-alert-triangle",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/paymentChannels",
              title: "Create Accounts",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/COA",
              title: "Chart Of Accounts",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/GeneralLedger",
              title: "General Ledgers",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/Trailbalance",
              title: "TrialBalance",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/ledgerEntries",
              title: "Journal Entries",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/ContraEntries",
              title: "Contra Entries",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/LedgerEntryEvents",
              title: "Post Entries",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/Voucher",
              title: "Voucher",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuAccounting.push({
              path: "/Accounting/BankReconciliation",
              title: "Bank Reconciliation",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            let data1 = this.menu.filter(
              (title) => title.menuName == "Accounting"
            );
            if (data1.length > 0) {
              this.sharedService.provideChartOfAccountss().subscribe((data) => {
                this.loginService.charOfAccName = data;
                data.forEach((element) => {
                  this.submenuAccountingStatements.push({
                    path:
                      "/Accounting/Account/" +
                      element["chartOfAccounts"]["Identifier"],
                    title: element["chartOfAccounts"]["Identifier"],
                    icon: "",
                    class: "",
                    badge: "",
                    badgeClass: "",
                    isExternalLink: false,
                    submenu: [],
                  });
                });
                this.submenuAccounting.push({
                  path: "/Accounting/Account/",
                  title: "Statements",
                  icon: "",
                  class: "has-sub",
                  badge: "",
                  badgeClass: "",
                  isExternalLink: false,
                  submenu: this.submenuAccountingStatements,
                });
              });
            }
            this.menutItemsRouter.splice(8, 0, {
              path: "",
              title: "Accounting",
              filter: "Accounting",
              icon: "icon-layers",
              class: "has-sub",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: this.submenuAccounting,
            });
            this.menutItemsRouter.push({
              path: "/DocumentStorage/DocumentStorage",
              title: "Document Storage",
              filter: "Document Storage",
              icon: "icon-docs",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/client-overview/ClientOverview",
              title: "Agent Tracking",
              filter: "Agent Tracking",
              icon: "ft-map",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/ProfileForms",
              title: "Profile Forms",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/Users",
              title: "Users",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/UserForms",
              title: "Users Form",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Branches/Branches",
              title: "Branches",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/Actions",
              title: "Actions",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/Roles",
              title: "Roles",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/WorkflowTemplate",
              title: "Workflow Template",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            submenu2.push({
              path: "/Setting/Workflow",
              title: "Workflow",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.splice(4, 0, {
              path: "",
              title: "OnBoard",
              filter: "OnBoard",
              icon: "icon-briefcase",
              class: "has-sub",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: this.submenu1,
            });
            this.menutItemsRouter = this.menutItemsRouter.filter(
              (menuItem) => menuItem
            );
            this.menutItemsRouter.push({
              path: "/calendar",
              title: "Calendar",
              filter: "Calendar",
              icon: "ft-calendar",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/agent-tracking/AgentTracking",
              title: "Client Overview",
              filter: "Client Overview",
              icon: "ft-map-pin",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/GeneratePayroll",
              title: "Generate Payroll",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/MailPayslip",
              title: "Mail Payslip",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/Payments",
              title: "Payments",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/RunPayroll",
              title: "Run Payroll",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/ViewPayroll",
              title: "View Payroll",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.submenuPayroll.push({
              path: "/Payroll/ViewPayslip",
              title: "View Payslip",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.splice(23, 0, {
              path: "",
              title: "Payroll",
              filter: "Payroll",
              icon: "ft-book",
              class: "has-sub",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: this.submenuPayroll,
            });
            this.menutItemsRouter.splice(24, 0, {
              path: "/FundingLine/FundingLine",
              title: "Link Funding Line",
              filter: "Link Funding Line",
              icon: "ft-link-2",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "/LoadDailySummary/LoadDailySummary",
              title: "Load Daily Summary",
              filter: "Load Daily Summary",
              icon: "ft-upload",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            subMenuSmartContract.push({
              path: "/CasperBlockChain/CasperBlockChain",
              title: "Security Tokens",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            subMenuSmartContract.push({
              path: "/CasperBlockChain/decentralized",
              title: "Tokenized Securities",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            subMenuSmartContract.push({
              path: "/deploy-log/deploy",
              title: "Deploy logs",
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.menutItemsRouter.push({
              path: "",
              title: "Tokenization Suite",
              filter: "Tokenization Suite",
              icon: "icon-layers",
              class: "has-sub",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: subMenuSmartContract,
            });
            this.menutItemsRouter = this.menutItemsRouter.filter(
              (menuItem) => menuItem
            );
            this.menu = this.menu.sort((a, b) => a.sequence - b.sequence);
            setTimeout(() => {
              this.menu.forEach((menu) => {
                const menuObj = this.menutItemsRouter.filter(
                  (mi) => mi.filter == menu["menuName"]
                )[0];
                this.menuNames.push(menuObj);
              });
              this.menuItems = this.menuNames.filter((menuItem) => menuItem);
            }, 500);
          }
        });
      });
    } else if (this.loginService.flag == "ROOTUSER") {
      let submenu2 = [];
      this.menutItemsRouter = [];
      let submenuClients = [];
      this.loginService.enableSetting = false;
      this.menutItemsRouter.push({
        path: "/Dashboard/Dashboard",
        title: "Dashboard",
        filter: "Dashboard",
        icon: "ft-home",
        class: "",
        badge: "",
        badgeClass: "badge badge-pill badge-danger float-right mr-1 mt-1",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/product-factory/product-factory",
        title: "Product Factory",
        filter: "Product factory",
        icon: "ft-grid",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/legal-agreement/legal-agreement",
        title: "Legal Agreements",
        filter: "Legal agreements",
        icon: "ft-file",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenuClients.push({
        path: "/Clients/Clients",
        title: "List of Clients",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenuClients.push({
        path: "/Branches/Branches",
        title: "Hierarchy",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "",
        title: "Clients",
        filter: "Clients",
        icon: "ft-users",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: submenuClients,
      });
      this.menutItemsRouter.push({
        path: "/Group/Grouping",
        title: "Groups",
        filter: "Groups",
        icon: "ft-users",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Approval/Approval",
        title: "Approve Client",
        filter: "Approve Client",
        icon: "ft-check-square",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Loans/Loans",
        title: "Accounts",
        filter: "Accounts",
        icon: "ft-briefcase",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Borrowings/Borrowings",
        title: "Borrowings",
        filter: "Borrowings",
        icon: "ft-briefcase",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/LinkCard/LinkCard",
        title: "Link Card",
        filter: "Link Card",
        icon: "ft-credit-card",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/BulkDisbursement/BulkDisbursement",
        title: "Bulk Deals",
        filter: "Bulk Deals",
        icon: "ft-box",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/BulkRepayment/BulkRepayment",
        title: "Bulk Receive",
        filter: "Bulk Receive",
        icon: "ft-box",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/DelinquencyManagement/DelinquencyManagement",
        title: "Delinquency Management",
        filter: "Delinquency Management",
        icon: "ft-alert-octagon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Portfolio/Portfolio",
        title: "Portfolio",
        filter: "Portfolio",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/LinkPortfolio",
        title: "Link PortFolio",
        filter: "Link PortFolio",
        icon: "ft-link-2",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/ReassignPortfolio/ReassignPortfolio",
        title: "Reassign Portfolio",
        filter: "Reassign Portfolio",
        icon: "ft-link-2",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Teller/Teller",
        title: "Teller",
        filter: "Teller",
        icon: "icon-drawer",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Receipt/Receipt",
        title: "Receipt",
        filter: "Receipt",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/ReceiptApproval/ReceiptApproval",
        title: "Receipt Approval",
        filter: "Receipt Approval",
        icon: "ft-file-text",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Reporting/Reporting",
        title: "Reporting",
        filter: "Reporting",
        icon: "ft-file-minus",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/Analysis/Analysis",
        title: "Analysis",
        filter: "Analysis",
        icon: "icon-bar-chart",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/DeleteContract/DeleteContract",
        title: "Exception Handler",
        filter: "Exception Handler",
        icon: "ft-alert-triangle",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/paymentChannels",
        title: "Create Accounts",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/COA",
        title: "Chart Of Accounts",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/GeneralLedger",
        title: "General Ledgers",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/Trailbalance",
        title: "TrialBalance",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/ledgerEntries",
        title: "Journal Entries",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/ContraEntries",
        title: "Contra Entries",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/LedgerEntryEvents",
        title: "Post Entries",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/Voucher",
        title: "Voucher",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuAccounting.push({
        path: "/Accounting/BankReconciliation",
        title: "Bank Reconciliation",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.sharedService.provideChartOfAccountss().subscribe((data) => {
        this.loginService.charOfAccName = data;
        data.forEach((element) => {
          this.submenuAccountingStatements.push({
            path:
              "/Accounting/Account/" + element["chartOfAccounts"]["Identifier"],
            title: element["chartOfAccounts"]["Identifier"],
            icon: "",
            class: "",
            badge: "",
            badgeClass: "",
            isExternalLink: false,
            submenu: [],
          });
        });
        this.submenuAccounting.push({
          path: "/Accounting/Account/",
          title: "Statements",
          icon: "",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: this.submenuAccountingStatements,
        });
      });
      this.menutItemsRouter.splice(10, 0, {
        path: "",
        title: "Accounting",
        filter: "Accounting",
        icon: "icon-layers",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: this.submenuAccounting,
      });
      this.menutItemsRouter.push({
        path: "/DocumentStorage/DocumentStorage",
        title: "Document Storage",
        filter: "Document Storage",
        icon: "icon-docs",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/client-overview/ClientOverview",
        title: "Agent Tracking",
        filter: "Agent Tracking",
        icon: "ft-map",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/ProfileForms",
        title: "Profile Forms",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/Users",
        title: "Users",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/UserForms",
        title: "Users Form",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Branches/Branches",
        title: "Branches",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/Actions",
        title: "Actions",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/Roles",
        title: "Roles",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/WorkflowTemplate",
        title: "Workflow Template",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      submenu2.push({
        path: "/Setting/Workflow",
        title: "Workflow",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });

      this.onBoardService.getWorkflows().subscribe((data) => {
        let workflows = data["data"]["workflow"].filter(wfData => (wfData.status == "active"))
        this.loginService.listOfWorkflows = workflows;
        this.loginService.workflowsLoaded = true;
        workflows.forEach((element) => {
          if (element.status == 'active') {
            console.debug(element)
            this.submenu1.push({
              path: "/OnBoard/onBoard/" + element["workFlowID"],
              title: element["workFlowName"],
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
            this.onBoardSideBar.push({
              path: "/OnBoard/onBoard/" + element["workFlowID"],
              title: element["workFlowName"],
              icon: "",
              class: "",
              badge: "",
              badgeClass: "",
              isExternalLink: false,
              submenu: [],
            });
          }
        });

        this.menutItemsRouter.splice(4, 0, {
          path: "",
          title: "OnBoard",
          filter: "OnBoard",
          icon: "icon-briefcase",
          class: "has-sub",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: this.submenu1,
        });
        this.menuItems = this.menutItemsRouter.filter((menuItem) => menuItem);
      });
      this.menutItemsRouter.push({
        path: "/calendar",
        title: "Calendar",
        filter: "Calendar",
        icon: "ft-calendar",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/agent-tracking/AgentTracking",
        title: "Client Overview",
        filter: "Client Overview",
        icon: "ft-map-pin",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/GeneratePayroll",
        title: "Generate Payroll",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/MailPayslip",
        title: "Mail Payslip",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/Payments",
        title: "Payments",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/RunPayroll",
        title: "Run Payroll",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/ViewPayroll",
        title: "View Payroll",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.submenuPayroll.push({
        path: "/Payroll/ViewPayslip",
        title: "View Payslip",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.splice(23, 0, {
        path: "",
        title: "Payroll",
        filter: "Payroll",
        icon: "ft-book",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: this.submenuPayroll,
      });
      this.menutItemsRouter.splice(24, 0, {
        path: "/FundingLine/FundingLine",
        title: "Link Funding Line",
        filter: "Link Funding Line",
        icon: "ft-link-2",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "/LoadDailySummary/LoadDailySummary",
        title: "Load Daily Summary",
        filter: "Load Daily Summary",
        icon: "ft-upload",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      subMenuSmartContract.push({
        path: "/CasperBlockChain/CasperBlockChain",
        title: "Security Tokens",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      subMenuSmartContract.push({
        path: "/CasperBlockChain/decentralized",
        title: "Tokenized Securities",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      subMenuSmartContract.push({
        path: "/deploy-log/deploy",
        title: "Deploy logs",
        icon: "",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
      });
      this.menutItemsRouter.push({
        path: "",
        title: "Tokenization Suite",
        filter: "Tokenization Suite",
        icon: "icon-layers",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: subMenuSmartContract,
      });
      setTimeout(() => {
        this.menuItems = this.menutItemsRouter.filter((menuItem) => menuItem);
      }, 500);
    }
  }

  setAppFavicon(faviconLink) {
    let favicon: HTMLLinkElement = document.querySelector("#favicon");
    favicon.href = faviconLink;
  }

  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }

  logo(imageUrl) {
    this.showLogo = !this.showLogo;
    this.imageUrl = imageUrl;
  }
}
