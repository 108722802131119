import { AbstractControl, FormGroup, ValidatorFn, ValidationErrors } from "@angular/forms";
export function ConfirmPasswordValidator(controlName: string, matchingControlName: string): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (control.value != matchingControl.value) {
            return { confirmPasswordValidator: true };
        } else {
            return null;
        }
    }
}


export function passwordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchingControl = formGroup.controls[matchingControlName]
        if (control.value != matchingControl.value) {
            return matchingControl.setErrors({ confirmPasswordValidator: true });
        } else {
            return matchingControl.setErrors(null);
        }
    }
}
