import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';
import { LoginService } from '../../login/login.service';
import { AppService } from '../../app.service';
import { Observable } from 'rxjs/Observable';
import swal from 'sweetalert2';

@Injectable()
export class RolesService {
  public editRoleDetails: any;
  public access_token: any;
  private headers = new Headers({ 'content-type': 'application/json', 'add_header': 'Access-Control-Allow-Origin', '$http_origin': 'always' });
  private options = new RequestOptions({ headers: this.headers });
  constructor(
    private http: Http,
    private loginService: LoginService, private commonservice: AppService,
  ) {
    this.access_token = loginService.access_tokenMethod();

  }

  getRoles() {
    return this.http.get(this.commonservice.roleURL + '/roles?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  createRole(roleDetails) {
    return this.http.post(this.commonservice.roleURL + '/users/role?access_token=' + this.access_token, roleDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  editRoleDetailsService(roleDetails, rolId) {
    return this.http.put(this.commonservice.roleURL + '/roles/' + rolId + '/actions?access_token=' + this.access_token, roleDetails).map((res: Response) => res.json())
      .catch(this.errorHandler);
  }

  deleteRole(deleteRoleID) {
    return this.http.delete(this.commonservice.roleURL + '/roles/' + deleteRoleID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getRole(roleID) {
    return this.http.get(this.commonservice.roleURL + '/roles/' + roleID + '?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideUserRolesByUser(userID) {
    return this.http.get(this.commonservice.roleURL + '/users/' + userID + '/roles?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }
  getActions() {
    return this.http.get(this.commonservice.roleURL + '/actions?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  getMenus() {
    return this.http.get(this.commonservice.menuURL + '/menu?access_token=' + this.access_token, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  linkMenuToRoles(payload) {
    return this.http.put(this.commonservice.menuURL + '/updateLinkMenuToRole?access_token=' + this.access_token, payload, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  provideLinkMenuToRole(roleId) {
    return this.http.get(this.commonservice.menuURL + '/provideLinkMenuToRole?access_token=' + this.access_token + '&roleId=' + roleId, this.options)
      .map((res: Response) => res.json()).catch(this.errorHandler);
  }

  errorHandler(error) {
    let errorMessage = JSON.parse(error._body)
    if (errorMessage.message == "Access is Denied" || errorMessage.message == "Access is denied") {
      swal("Sorry!", errorMessage.message, "error");
    }else if (errorMessage.status !== 200) {
      swal("Sorry!", errorMessage.message, "error");
    }
    return Observable.throw(error || " server error ");
  }


}
